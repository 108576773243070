/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/site.css"

/**
 * Try and prevent flash of un-sized FA icons
 */
import '@fortawesome/fontawesome-svg-core/styles.css';
// import { config } from '@fortawesome/fontawesome-svg-core'
// config.autoAddCss = false

require('typeface-open-sans');
require('typeface-vollkorn');